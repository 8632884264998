* {
  padding: 0;
  margin: 0;
}
body {
  background: #0e0f14;
  color: #fff;
  font-size: 14px;
}
a {
  color: #fff;
  text-decoration: none;
}
.mb20 {
  margin-bottom: 20px;
}
.mr20 {
  margin-right: 20px;
}
.clear {
  clear: both;
}
footer {
  background-image: linear-gradient(#0e0f14, #181e5a);
}
.logo-img {
  width: 50px;
  height: 50px;
  margin: 0 7px 0 0;
}
.logo-text {
  width: 197px;
  height: 30px;
}
.red {
  color: #e63e5a;
}
.green {
  color: #5cc7bd;
}
header {
  padding: 24px 0;
  border-bottom: 1px solid #2c2e32;
}
.flex-sb-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-l {
  width: 1000px;
  margin: 0 auto;
}
.container-s {
  width: 890px;
  margin: 0 auto;
}
.nav {
  width: 600px;
}
.btn {
  line-height: calc(1rem + 24px);
  border-radius: 20px;
  padding: 7px 25px;
  background: #356ff0;
  border: 1px solid #356ff0;
  margin: 0 5px;
}
.btn.plain {
  background: none;
  border-color: #fff;
}
.banner {
  margin: 60px 0;
}
.banner .container-l {
  box-sizing: border-box;
  height: 525px;
  background: url("1270caf673d3e89b8f2291e4ba863cb1.png") no-repeat bottom right;
  background-size: auto 500px;
  padding-top: 100px;
}
.banner p {
  font-size: 46px;
  margin-bottom: 10px;
}
.banner-grid {
  margin: 60px 0;
}
.banner-grid .container-s {
  border-radius: 12px;
  background: #272b34;
  padding: 20px;
  box-sizing: border-box;
}
.banner-grid .container-s dd {
  font-size: 1.6rem;
}
.banner-grid .container-s dt,
.banner-grid .container-s dd {
  text-align: center;
  font-weight: bold;
}
.banner-grid .container-s dl {
  padding: 10px 20px;
  border-radius: 12px;
}
.banner-grid .container-s dl.on {
  background: #3e424e;
}
h3 {
  font-size: 2em;
  margin-bottom: 10px;
}
h4 {
  font-size: 1.4em;
  margin-bottom: 10px;
}
.common-banner {
  text-align: center;
  margin: 200px auto;
}
.common-banner p {
  color: #868792;
  margin-bottom: 10px;
  font-size: 1.2em;
}
.banner-computer {
  position: relative;
}
.banner-computer .bg-left {
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: 0;
}
.banner-computer .bg-right {
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
}
footer {
  text-align: center;
  padding: 30px 0 30px 0;
}
footer .logo {
  width: 254px;
  margin: 0 auto 10px auto;
}
footer .qr {
  width: 80px;
}
footer p {
  margin-bottom: 10px;
}
.faq {
  width: 880px;
  margin: 30px auto;
}
.faq h2 {
  font-size: 1.8em;
  margin: 0 0 50px 0;
}
.faq dt {
  font-size: 1.4em;
  margin: 0 0 10px 0;
}
.faq dd {
  font-size: 1.1em;
  margin: 0 0 50px 0;
}
.faq img {
  max-width: 100%;
}
.faq strong {
  font-weight: bolder;
}
.images-contrast {
  width: 840px;
  height: 549px;
  position: relative;
  margin: 0 auto;
}
.images-contrast .img-container {
  background: #0e0f14;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 33.3%;
  height: 549px;
  transition: all 0.4s;
}
.images-contrast .img-container img {
  position: absolute;
  width: 840px;
  height: 509px;
}
.images-contrast .img-container:nth-of-type(1) img {
  left: 0;
}
.images-contrast .img-container:nth-of-type(2) {
  left: 33.3%;
}
.images-contrast .img-container:nth-of-type(2) img {
  left: 50%;
  transform: translateX(-50%);
}
.images-contrast .img-container:nth-of-type(3) {
  left: 66.6%;
}
.images-contrast .img-container:nth-of-type(3) img {
  right: 0;
}
.images-contrast .img-container:hover {
  left: 0;
  right: 0;
  z-index: 3;
  width: 100%;
}
.images-contrast .img-container p {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.animation.inplace {
  opacity: 0.5;
  transform: translate(0, 100px);
}
.login a {
  vertical-align: middle;
}
.userinfo {
  display: inline-block;
  text-align: center;
  margin-left: 30px;
}
.userinfo .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.swiper {
  width: 800px;
  user-select: none;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}
@-moz-keyframes move {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(30px, 30px);
  }
}
@-webkit-keyframes move {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(30px, 30px);
  }
}
@-o-keyframes move {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(30px, 30px);
  }
}
@keyframes move {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(30px, 30px);
  }
}
